<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="dialogForm"
      :before-close="handleClose">
    <el-form size="small" ref="inputForm" :model="inputForm" :rules="rules" label-width="100px">
      <el-form-item label="修复文件" prop="repairResultFileList">
        <resume-upload :dir="'collection'" ref="resume-upload" :filtButton="true" :uploadNum="flieNumber"
                       :allowFiles="fileType"
                       @upFileDate="(data)=>getUpData(data)"></resume-upload>
        <div v-for="(item,index) in fileList" style="margin-bottom: 10px">
          <el-card class="box-card">
            <div class="flex_b_c">
              <div class="el-icon-notebook-2" style="cursor: pointer" @click="previewPdf(item.netUrl)"></div>
              <div style="flex: 1;margin: 0 20px;cursor: pointer" @click="previewPdf(item.netUrl)">
                <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top-start">
                  <div class="omit">{{ item.fileName }}</div>
                </el-tooltip>
              </div>
              <div class="el-icon-circle-close" style="cursor: pointer"
                   @click="deteliFile(item,index)"></div>
            </div>
          </el-card>
        </div>
      </el-form-item>
      <el-form-item label="病害情况" prop="resultDisease">
        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                  v-model.trim="inputForm.resultDisease" maxlength="200"
                  placeholder="请输入目前病害情况(限200字)"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="修复前说明" prop="resultBeforeIllustrate">
        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                  v-model.trim="inputForm.resultBeforeIllustrate" maxlength="200"
                  placeholder="请输入修复前说明(限200字)"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="修复中说明" prop="resultDuringIllustrate">
        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                  v-model.trim="inputForm.resultDuringIllustrate" maxlength="200"
                  placeholder="请输入修复中说明(限200字)"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="修复后说明" prop="resultAfterIllustrate">
        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                  v-model.trim="inputForm.resultAfterIllustrate" maxlength="200"
                  placeholder="请输入修复后说明(限200字)"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="修复效果说明" prop="resultEffectIllustrate">
        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                  v-model.trim="inputForm.resultEffectIllustrate" maxlength="200"
                  placeholder="请输入修复效果说明(限200字)"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="resultRemark">
        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                  v-model.trim="inputForm.resultRemark" maxlength="200"
                  placeholder="请输入备注(限200字)"
                  clearable></el-input>
      </el-form-item>
    </el-form>
    <div class="text_center" slot="footer">
      <el-button size="small" type="primary" @click="sureData()" v-no-more-click>确定</el-button>
      <el-button size="small" @click="handleClose()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";
import {Base64} from 'js-base64'

export default {
  name: "repairResults",
  components: {ResumeUpload},
  data() {
    return {
      title: '修复结果',
      dialogVisible: false,
      dataId: '',
      repairId: '',
      inputForm: {
        resultDisease: '',
        resultBeforeIllustrate: '',
        resultDuringIllustrate: '',
        resultAfterIllustrate: '',
        resultEffectIllustrate: '',
        resultRemark: '',
        repairResultFileList:[],
      },
      rules: {
        repairResultFileList: [
          { type: 'array', required: true, message: '请上传修复文件', trigger: 'change' }
        ],
      },
      inputType: false,

      //文件上传
      flieNumber: 5,
      fileList: [],
      fileType: ['PDF'],
    }
  },

  mounted() {
  },

  methods: {
    init(dataId, repairId) {
      if (dataId) {
        this.dataId = dataId
        this.repairId = repairId
      }
      if(typeof this.dataId == 'string'){
        this.$axios(this.api.collection.repairResultById, {
          dataId: this.dataId,
          repairId: this.repairId,
        }, 'get').then(data => {
          if (data.status && data.data) {
            this.inputForm = data.data
            this.fileList = data.data.repairResultFileList
            this.fileList.forEach((item,index)=>{
              this.$set(item,'id',index)
            })
            this.$refs['resume-upload'].setOldFile(this.fileList)
          }
        })
      }
      this.dialogVisible = true
    },

    getUpData(data) {
      this.fileList = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      this.inputForm.repairResultFileList = this.fileList
      console.log(this.fileList)
    },

    //pdf预览
    previewPdf(url) {
      this.pdfUrl = url
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(this.pdfUrl)));
    },

    //删除文件
    deteliFile(row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index, 1)
        this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
      }).catch(() => {
      })
    },

    sureData() {
      this.$refs.inputForm.validate((valid) => {
        if (valid) {
          if (typeof this.dataId == 'string'){
            this.$axios(this.api.collection.repairResultSave, {
              ...this.inputForm,
              dataId: this.dataId,
              repairId: this.repairId,
            }, 'post').then(data => {
              if (data.status) {
                this.$message.success('保存成功')
                this.$emit('sureDa')
                this.handleClose()
              } else {
                this.$message.error(data.msg)
              }
            })
          }else {
            this.$axios(this.api.collection.repairResultBatchSave, {
              ...this.inputForm,
              collectionId: this.dataId,
              repairId: this.repairId,
            }, 'post').then(data => {
              if (data.status) {
                this.$message.success('保存成功')
                this.$emit('sureDa')
                this.handleClose()
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        }
      })
    },

    handleClose() {
      if (this.fileList.length) {
        this.fileList.forEach(item => {
          this.$refs["resume-upload"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
        })
        this.fileList = []
      }
      this.$refs.inputForm.resetFields()
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>

</style>